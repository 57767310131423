import React from 'react'

const Nopage = () => {
    return (
        <div>
            <h2 className="text-white">Page Not Found</h2>
        </div>
    )
}

export default Nopage
