import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const WarrantyInprogress = () => {
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceVerification, setDeviceVerification] = useState({
        diagnoseDone: "",
    });

    useEffect(() => {
        getAllDevices();
    }, []);

    const getAllDevices = async () => {
        try {
            const response = await fetch('https://api.intimeglobal.ae/inprogressdevices');
            const data = await response.json();
            setDevices(data.devices);
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };

    const openModal = (device) => {
        setSelectedDevice(device);
    };

    const closeModal = () => {
        setSelectedDevice(null);
    };


    const handleDeviceSelection = (device) => {
        setDeviceVerification({
            diagnoseDone: device.deviceinfo.diagnoseDone,
        });
        setSelectedDevice(device);
    };

    const updateDeviceDiagnose = async (deviceId) => {
        console.log(deviceVerification, deviceId);

        try {
            const response = await fetch(`https://api.intimeglobal.ae/updateDiagnose/${deviceId}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({ deviceVerification })
            });

            const result = await response.json();
            console.log(result);
            if (result.status === "ok") {
                alert(result.message);
                console.log(result.data);
            }
        } catch (error) {
            console.error('Error fetching all details:', error);
        }


        setDeviceVerification({
            diagnoseDone: "",
        })
    };

    return (
        <div className="bg_white_inner">
            <section className="main_content dashboard_part large_header_bg">
                <Header />
                <div className="page_content">
                    <>
                        <h3>Warranty Devices</h3>
                        {devices.length > 0 ? (
                            <table width="100%" className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                        <th>Device Verified</th>
                                        <th>Diagnose Done</th>
                                        <th>Claim Warranty</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices.map(device => (
                                        <tr key={device._id}>
                                            <td>{device.deviceinfo.deviceId}</td>
                                            <td className="text-capitalize">{device.deviceinfo.deviceverified}</td>
                                            <td className="text-capitalize">{device.deviceinfo.diagnoseDone} {device.deviceinfo.diagnoseDone ? new Date(device.deviceinfo.diagnoseDoneDate).toLocaleDateString() : "-"}</td>
                                            <td className="text-capitalize">{device.deviceinfo.claimwarranty ? "Yes" : "No"}</td>
                                            <td>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Link to="#" onClick={() => openModal(device)}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to="#">
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to="#">
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No devices in progress</p>
                        )}

                        {selectedDevice && (
                            <div className="modal shadow" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className=" col">
                                                <h5 className="modal-title">Devices Details</h5>
                                            </div>
                                            <div className="col">
                                                <button type="button" className="close text-right" onClick={closeModal}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="modal-body">

                                            <table width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>Diagnose Status</td>
                                                        <td>
                                                            {selectedDevice.deviceinfo.diagnoseDone === "inprogress" ? (
                                                                <select
                                                                    value={deviceVerification.diagnoseDone}
                                                                    onChange={(e) => setDeviceVerification({ ...deviceVerification, diagnoseDone: e.target.value })}
                                                                >
                                                                    <option>Select</option>
                                                                    <option value="completed">Completed</option>
                                                                </select>
                                                            ) : (
                                                                selectedDevice.deviceinfo.diagnoseDone
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => updateDeviceDiagnose(selectedDevice.deviceinfo.deviceId)}
                                                            >
                                                                Update
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table width="100%" className="table table-striped table-hover">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h5>Device Details - {selectedDevice.deviceinfo.deviceId}</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Model Name</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.modelname}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Model No</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.modelno}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Color</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.color}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Memory</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.memory}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Serial</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.serial}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>IMEI 1</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.imei1}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>IMEI 2</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.imei2}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Carrier</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.carrier}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Firmware</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.firmware}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Version</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.version}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>OS</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.os}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sim Lock</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.simlock}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Jail Break</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.jailbreak}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Region Code</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.regioncode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Battery Serial</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.batteryserial}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Battery Health</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.batteryhealth}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Design Capacity</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.designcapacity}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Current Capacity</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.currentcapacity}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Cycle Count</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.cyclecount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Temperature</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.temperature}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Technician Email</td>
                                                                    <td>{selectedDevice.assigntechnicianemail}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Condition</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.condition}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Grade</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.grade}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Comments</td>
                                                                    <td>{selectedDevice.deviceinfo.devicedetails.comments}</td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                        <td>
                                                            <table width="100%" className="table table-striped table-hover">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h5>Functional Diagnostics</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Front Mic</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.frontmic ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Proximity</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.proximity ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gyroscope</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.gyroscope ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Power Button</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.powerbutton ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ultra Wide Camera Quality</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.ultrawidecameraquality ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>NFC</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.nfc ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Ear Piece</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.earpiece ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>WiFi</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.wifi ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Accelerometer</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.accelerometer ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rear Camera</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.rearcamera ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Digitizer</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.digitizer ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Volume Down Button</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.volumedownbutton ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Volume Up Button</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnosticsvolumeupbutton ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rear Mic Quality</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.rearmicquality ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bottom Mic</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.bottommic ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bottom Mic Quality</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.bottommicquality ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Flash</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.flash ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Front Mic Quality</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.fromtmicquality ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rear Mic</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.rearmic ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Front Camera</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.frontcamera ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Screen Rotation</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.screenrotation ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Device Vibration</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.devicevibration ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Front Camera Quality</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.frontcameraquality ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bluetooth</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.bluetooth ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Loud Speaker</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.loudspeaker ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Flip</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.flip ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Comments Functional</td>
                                                                    <td>{selectedDevice.deviceinfo.functionaldiagnostics.commentsfunctional}</td>
                                                                </tr>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table width="100%" className="table table-striped table-hover">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h5>Cosmetics Details</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Water Proof</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetails.waterproof ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>OEM LCD</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetails.oemlcd ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Front Glass Change</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetails.frontglasschange ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Front Glass Buff</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetails.frontglassbuff ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Back Cover Change</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetails.backcoverchange ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Back Cover Buff</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetails.backcoverbuff ? "Yes" : "No"}</td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td>
                                                            <table width="100%" className="table table-striped table-hover">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h5>Cosmetics Details mm</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Back Cover With Left Dent Mark</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.backcoverwithleftdentmark}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dent on Charging Point</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.dentonchargingport}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Scratch on Front Glass</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.scratchonfrontglass}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Scratch on Back Glass</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.scratchonbackglass}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Scratch on Back Camera</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.scratchonbackcamera}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dot in LCD</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.dotinlcd}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dot in Camera 1</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.dotincamera1}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dot in Camera 2</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.dotincamera2}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dot in Camera 3</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.dotincamera3}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Comments Cosmetics</td>
                                                                    <td>{selectedDevice.deviceinfo.cosmeticsdetailsmm.commentscosmetics}</td>
                                                                </tr>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="2">
                                                            <table width="100%">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h5>Device Images</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <table width="100%">
                                                                            <tr>
                                                                                <td colspan="2">
                                                                                    <Link to={selectedDevice.deviceinfo.diagnoseImages.Photo1} target='_blank'>
                                                                                        <img src={selectedDevice.deviceinfo.diagnoseImages.Photo1} width="120" alt="" />
                                                                                    </Link>
                                                                                </td>
                                                                                <td colspan="2">
                                                                                    <Link to={selectedDevice.deviceinfo.diagnoseImages.Photo2} target='_blank'>
                                                                                        <img src={selectedDevice.deviceinfo.diagnoseImages.Photo2} width="120" alt="" />
                                                                                    </Link>
                                                                                </td>
                                                                                <td colspan="2">
                                                                                    <Link to={selectedDevice.deviceinfo.diagnoseImages.Photo3} target='_blank'>
                                                                                        <img src={selectedDevice.deviceinfo.diagnoseImages.Photo3} width="120" alt="" />
                                                                                    </Link>
                                                                                </td>
                                                                                <td colspan="2">
                                                                                    <Link to={selectedDevice.deviceinfo.diagnoseImages.Photo4} target='_blank'>
                                                                                        <img src={selectedDevice.deviceinfo.diagnoseImages.Photo4} width="120" alt="" />
                                                                                    </Link>
                                                                                </td>

                                                                                <td colspan="2">
                                                                                    <Link to={selectedDevice.deviceinfo.diagnoseImages.Photo5} target='_blank'>
                                                                                        <img src={selectedDevice.deviceinfo.diagnoseImages.Photo5} width="120" alt="" />
                                                                                    </Link>
                                                                                </td>

                                                                                <td colspan="2">
                                                                                    <Link to={selectedDevice.deviceinfo.diagnoseImages.Photo6} target='_blank'>
                                                                                        <img src={selectedDevice.deviceinfo.diagnoseImages.Photo6} width="120" alt="" />
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {/* Add more details as needed */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </section >
        </div >
    );
};

export default WarrantyInprogress;
