import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        const { username, email, phone, password } = formData;

        try {
            const response = await fetch("https://api.intimeglobal.ae/adminregister", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    email,
                    username,
                    phone,
                    password,
                }),
            });

            if (!response.ok) {
                throw new Error('Registration failed.');
            }

            const data = await response.json();
            console.log(data);
            alert('Registration Successful');
            window.location.href = "/";
            // redirect('./login');
            // navigate("/login");

        } catch (error) {
            console.error('Error during registration:', error.message);
            alert('Registration Error');
        }
    };


    return (
        <div className="loginregister">
            <div className="background">
                <div className="shape"></div>
                <div className="shape"></div>
            </div>
            <form onSubmit={handleSubmit}>
                <h3>Register Here</h3>

                <label>Username</label>
                <input
                    type="text"
                    placeholder="Username"
                    id="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                />

                <label>Email</label>
                <input
                    type="text"
                    placeholder="Enter Email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <label>Phone</label>
                <input
                    type="text"
                    placeholder="Enter Phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />

                <label>Password</label>
                <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />

                <button type="submit">Register</button>

                <div className="register_area">
                    <Link to="/">Login</Link>
                </div>

            </form>
        </div>
    )
}

export default Register
