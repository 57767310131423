import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

const VendorRegister = () => {

    const [vendors, setvendors] = useState([]);
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        getAllvendors();
        getAllRequests();
    }, [])

    const getAllvendors = async () => {
        try {
            const response = await fetch("https://api.intimeglobal.ae/vendors", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });

            const data = await response.json();

            if (data.status === "ok") {
                setvendors(data.vendors);
            } else {
                alert('Registration Error', 'danger');
            }
        } catch (error) {
            console.error('Error during registration:', error.message);
            alert('Registration Error', 'danger');
        }
    }


    const getAllRequests = async () => {
        const token = window.localStorage.getItem('token');

        try {
            const response = await fetch("https://api.intimeglobal.ae/userRequest", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': `Bearer ${token}`,
                }
            });
            // console.log("Show data");
            const data = await response.json();
            // console.log(data);
            if (data.status === "ok") {
                setRequests(data.data);
            } else {
                console.log("Error Showing")
            }
        } catch (error) {
            console.error("Something went wrong:", error);
        }
    }



    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, email, phone, password } = formData;

        try {
            const response = await fetch("https://api.intimeglobal.ae/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    email,
                    username,
                    phone,
                    password,
                }),
            });

            const data = await response.json();

            if (data.status === "ok") {
                alert('Registration Successful', 'success');
            } else {
                alert('Registration Error', 'danger');
            }
        } catch (error) {
            console.error('Error during registration:', error.message);
            alert('Registration Error', 'danger');
        }
    };



    return (
        <div className="bg_white_inner">
            <section className="main_content dashboard_part large_header_bg">
                <Header />
                <div className="page_content">
                    <div className="row">
                        <div className="col-xl-6">
                            <h4>All Vendors</h4>
                        </div>
                        <div className="col-xl-6 text-right">
                            <button type="button" class="btn btn-primary w-auto" data-toggle="modal" data-target="#exampleModalCenter">
                                Register New Vendor
                            </button>
                        </div>
                        <div className="col-xl-12 mt-4">
                            <table cellpadding="0" cellspacing="0" width="100%" className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>All Requests</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendors.length > 0 && vendors.map(vendor => (
                                        <tr>
                                            <td>{vendor.username}</td>
                                            <td>{vendor.email}</td>
                                            <td>{vendor.phone}</td>
                                            <td>View All Requests</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header w-100">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <h5 class="modal-title" id="exampleModalLongTitle">Register Vendor</h5>
                                        </div>
                                        <div className="col-xl-6 text-right">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <form onSubmit={handleSubmit}>
                                            <label for="username">Username</label>
                                            <input
                                                type="text"
                                                placeholder="Username"
                                                id="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label for="email">Email</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label for="phone">Phone</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Phone"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label for="password">Password</label>
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                id="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />

                                            <button type="submit" class="btn btn-primary mt-3">Register</button>

                                        </form>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* {(requests.length > 0) && requests.slice().reverse().map((request, index) => (
                        <div key={request.requestID}>
                            <button
                                onClick={() => { toggleCollapse(index); getAllDevices(request.requestID) }}
                            >
                                <div>
                                    <div>{request.requestID.split("-")[0]}</div>
                                    <div>{new Date(request.createdAt).toISOString().slice(0, 10)}</div>
                                </div>
                            </button>

                            <div collapsed={!collapsedIndexes.includes(index)}>
                                <div>
                                    {devices.length > 0 && devices.map((device, index) => (
                                        <div key={device.deviceinfo.deviceId}>
                                            <div>
                                                <div>Device Id</div>
                                                <div>{device.deviceinfo.deviceId}</div>
                                            </div>
                                            <div>
                                                <div>Model Name</div>
                                                <div>{device.deviceinfo.devicedetails.modelname}</div>
                                            </div>
                                            <div>
                                                <div>Model No</div>
                                                <div>{device.deviceinfo.devicedetails.modelno}</div>
                                            </div>
                                            <div>
                                                <div>Color</div>
                                                <div>{device.deviceinfo.devicedetails.color}</div>
                                            </div>
                                            <div>
                                                <div>Memory</div>
                                                <div>{device.deviceinfo.devicedetails.memory}</div>
                                            </div>
                                            <div>
                                                <div>Serial</div>
                                                <div>{device.deviceinfo.devicedetails.serial}</div>
                                            </div>
                                            <div>
                                                <div>IMEI 1</div>
                                                <div>{device.deviceinfo.devicedetails.imei1}</div>
                                            </div>
                                            <div>
                                                <div>IMEI 2</div>
                                                <div>{device.deviceinfo.devicedetails.imei2}</div>
                                            </div>
                                            <div>
                                                <div>Dignose Done</div>
                                                <div>{device.deviceinfo.diagnoseDone}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))} */}


                </div>
            </section>
        </div>
    )
}

export default VendorRegister
