import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Home = () => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api.intimeglobal.ae/admin-login', formData);

            if (response.data.status === "ok") {
                alert('Login Successful', 'success');
                window.localStorage.setItem("token", response.data.token);
                window.localStorage.setItem("loggedIn", true);
                window.location.href = "/";
                // navigate("/dashboard");
            } else {
                alert('Login Error: ' + response.data.message, 'danger');
            }
        } catch (error) {
            console.error('Error during login:', error.message);
            alert('Login Error', 'danger');
        }
    };

    return (
        <div className="loginregister">
            <div className="background">
                <div className="shape"></div>
                <div className="shape"></div>
            </div>
            <form onSubmit={handleSubmit}>
                <h3>Login Here</h3>

                <label htmlFor="email">Email or Phone</label>
                <input
                    type="text"
                    placeholder="Email or Phone"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />

                <button type="submit">Log In</button>

                <div className="register_area">
                    <Link to="/register">Register</Link>
                </div>
            </form>
        </div>
    );
};

export default Home;
