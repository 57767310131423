import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';


const Recentrequest = () => {
    const [requests, setRequests] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);
    const [deviceVerification, setDeviceVerification] = useState({
        deviceverified: "",
        deviceverifiedcomments: ""
    });
    const [showModal, setShowModal] = useState(false);
    const [technicians, setTechnicians] = useState([]);
    const [assigntechnician, setAssigntechnician] = useState("");

    useEffect(() => {
        const fetchAllRequests = async () => {
            try {
                const response = await axios.get('https://api.intimeglobal.ae/getAllVendorRequest');
                const data = response.data;

                if (data.success) {
                    setRequests(data.details);
                } else {
                    console.error('Error fetching all details:', data.message);
                }
            } catch (error) {
                console.error('Error fetching all details:', error);
            }
        };

        fetchAllRequests();
        /* The line `<Text>Received Request</Text>` is rendering a text component with the text "Received
        Request". This text will be displayed on the screen when the component is rendered. */
        getAllTechnicians();
    }, []);

    const getAllDevices = async (request) => {
        const requestId = request.requestID;
        try {
            const response = await fetch(`https://api.intimeglobal.ae/getAllDevices/${requestId}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            });

            const result = await response.json();
            if (result.status === "ok") {
                setSelectedDevice(result.allDevices);
                setShowModal(true);
                console.log(result.allDevices);
            }
        } catch (error) {
            console.error('Error fetching all details:', error);
        }
    };


    const handleDeviceSelection = (device) => {
        setDeviceVerification({
            ...deviceVerification,
            [device.deviceId]: {
                deviceverified: device.devicedetails.deviceverified,
                deviceverifiedcomments: device.deviceverifiedcomments,
            },
        });

        setSelectedDevice(device);

    };

    const updateDeviceVerification = async (deviceId) => {
        console.log(deviceVerification, deviceId);

        try {
            const response = await fetch(`https://api.intimeglobal.ae/updateDevice/${deviceId}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({ deviceVerification })
            });

            const result = await response.json();
            console.log(result);
            if (result.status === "ok") {
                alert(result.message);
                console.log(result.data);
            }
        } catch (error) {
            console.error('Error fetching all details:', error);
        }


        setDeviceVerification({
            deviceverified: "",
            deviceverifiedcomments: "",
        })
    };

    const getAllTechnicians = async () => {
        try {
            const response = await fetch("https://api.intimeglobal.ae/availabletechnicians", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });

            const data = await response.json();

            if (data.status === "ok") {
                setTechnicians(data.technicans);
            } else {
                setTechnicians(data.technicians);
            }
        } catch (error) {
            console.error('Error during registration:', error.message);
            alert('Registration Error', 'danger');
        }
    }

    const assignTechnician = async (requestID) => {
        try {
            const response = await fetch("https://api.intimeglobal.ae/assigntechnician", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    requestID: requestID,
                    technicianemail: assigntechnician
                }),
            });

            const data = await response.json();

            if (data.status === "ok") {
                alert(data.message, 'success');
            } else {
                alert('Registration Error', 'danger');
            }
        } catch (error) {
            console.error('Error during registration:', error.message);
            alert('Registration Error', 'danger');
        }
    }

    return (
        <div className="bg_white_inner">
            <section className="main_content dashboard_part large_header_bg">
                <Header />
                <div className="page_content">
                    <h4>Recent Request</h4>
                    <div className="table_data">
                        <table className="maintable" cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr>
                                    <th width="20%">Request ID</th>
                                    <th width="20%">Total Devices</th>
                                    <th scope="col">Technician</th>
                                    <th width="10%">Created At</th>
                                    <th width="10%">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requests.slice().reverse().map((request, index) => (
                                    <tr key={index} className={index === 0 ? 'highlighted-row' : ''}>
                                        <td>
                                            {request.requestID.split("-")[0]}

                                        </td>
                                        <td>{request.totalDevices}</td>

                                        {request.assigntechnician ? <td>{request.assigntechnicianemail}</td> :
                                            <td>
                                                <select onChange={(e) => setAssigntechnician(e.target.value)}>
                                                    <option>Select Technician</option>
                                                    {technicians.length > 0 && technicians.map(technician => (
                                                        <option value={technician.email}>
                                                            {technician.email}
                                                        </option>
                                                    ))}
                                                </select>
                                                <button type='submit' onClick={() => assignTechnician(request.requestID)} >Submit</button>
                                            </td>
                                        }

                                        <td className="text-capitalize">{request.createdAt}</td>
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Link to="#" onClick={() => getAllDevices(request)}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to="#">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to="#">
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            {selectedDevice.length > 0 && (
                <div class="container-fluid w-100">
                    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className=" col">
                                        <h5 className="modal-title">Devices Details</h5>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="close text-right" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Device ID</th>
                                                <th scope="col">IMEI 1</th>
                                                <th scope="col">IMEI 2</th>
                                                <th scope="col">Change Status</th>
                                                <th scope="col">Comments</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDevice.map((device) => (
                                                <tr key={device.deviceinfo.deviceId}>
                                                    <td>{device.deviceinfo.deviceId}</td>
                                                    <td>{device.deviceinfo.devicedetails.imei1}</td>
                                                    <td>{device.deviceinfo.devicedetails.imei2}</td>
                                                    <td>
                                                        {device.deviceinfo.deviceverified === "pending" ? <select

                                                            onChange={(e) => setDeviceVerification({ ...deviceVerification, deviceverified: e.target.value })}
                                                        >
                                                            <option>Select</option>
                                                            <option value="approved">Approved</option>
                                                            <option value="reject">Reject</option>
                                                        </select> : device.deviceinfo.deviceverified}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setDeviceVerification({
                                                                ...deviceVerification,
                                                                deviceverifiedcomments: e.target.value
                                                            })}
                                                        />
                                                    </td>
                                                    <td colSpan="6">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => updateDeviceVerification(device.deviceinfo.deviceId)}
                                                        >
                                                            Update
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default Recentrequest;
