import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faBars,
    faBell,
    faMessage,
} from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    return (
        <div className="topbar_area">
            <div className="humberger_menu">
                <Link to="#">
                    <FontAwesomeIcon icon={faBars} />
                </Link>
            </div>
            <div className="right_notification_section">
                <ul>
                    <li>
                        <Link to="#">
                            <FontAwesomeIcon icon={faBell} />
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <FontAwesomeIcon icon={faMessage} />
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <FontAwesomeIcon icon={faUser} />
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header;