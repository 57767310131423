import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faGaugeHigh,
    faCartShopping,
    faAward,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        localStorage.removeItem('token');
        localStorage.setItem("loggedIn", "false");
        window.location.href = "/";
    };

    return (
        <div>
            <nav className="sidebar">
                <div className="logo d-flex justify-content-between">
                    {/* <a className="large_logo" href="#"><img src={process.env.PUBLIC_URL + '/intime-logo.png'} alt="" /></a> */}
                    {/* <a className="small_logo" href="#"><img src={process.env.PUBLIC_URL + '/intime-logo.png'} alt="" /></a> */}
                    <div className="sidebar_close_icon d-lg-none">
                        <i className="ti-close"></i>
                    </div>
                </div>
                <ul id="sidebar_menu" className="metismenu">
                    <li>
                        <Link to="/dashboard" aria-expanded="false">
                            <div className="nav_icon_small">
                                <FontAwesomeIcon icon={faGaugeHigh} />
                            </div>
                            <div className="nav_title">
                                <span><strong>Dashboard</strong> </span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/recent-request" aria-expanded="false">
                            <div className="nav_icon_small">
                                <FontAwesomeIcon icon={faCartShopping} />
                            </div>
                            <div className="nav_title">
                                <span><strong>Recent Request</strong> </span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/device-warranty" aria-expanded="false">
                            <div className="nav_icon_small">
                                <FontAwesomeIcon icon={faAward} />
                            </div>
                            <div className="nav_title">
                                <span><strong>Devices Warranty</strong> </span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/technician-register" aria-expanded="false">
                            <div className="nav_icon_small">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="nav_title">
                                <span><strong>Technician Register</strong></span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/vendor-register" aria-expanded="false">
                            <div className="nav_icon_small">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="nav_title">
                                <span><strong>Vendor Requests</strong></span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/warranty-progress" aria-expanded="false">
                            <div className='nav_icon_small'>
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="nav_title">
                                <span>
                                    <strong>
                                        Warranty In Progress
                                    </strong>
                                </span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="#" aria-expanded="false" onClick={handleLogout}>
                            <div className="nav_icon_small">
                                <FontAwesomeIcon icon={faRightFromBracket} />
                            </div>
                            <div className="nav_title">
                                <span><strong>Logout</strong> </span>
                            </div>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar;

