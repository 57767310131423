import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

const DeviceWarranty = () => {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        getAllWarrantyDevices();
    }, []);

    let date;

    const getAllWarrantyDevices = async () => {
        try {
            const response = await fetch('https://api.intimeglobal.ae/warrantydevices', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            });
            // console.log(response)
            const data = await response.json();
            // console.log(data.data);
            setDevices(data.data);


        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };

    return (
        <div className="bg_white_inner">
            <section className="main_content dashboard_part large_header_bg">
                <Header />
                <div className="page_content">
                    <>
                        <h4>Device Warranty</h4>
                        {devices.length > 0 ? (
                            <table width="100%" className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                        <th>Device Verified</th>
                                        <th>Diagnose Done</th>
                                        <th>Claim Warranty</th>
                                        <th>Warranty Days Left</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices.map(device => (
                                        <tr key={device._id}>
                                            <td>{device.deviceinfo.deviceId}</td>
                                            <td className="text-capitalize">{device.deviceinfo.deviceverified}</td>
                                            <td className="text-capitalize">{device.deviceinfo.diagnoseDone} {device.deviceinfo.diagnoseDone ? new Date(device.deviceinfo.diagnoseDoneDate).toLocaleDateString() : "-"}</td>
                                            <td className="text-capitalize">{device.deviceinfo.claimwarranty ? "Yes" : "No"}</td>
                                            <td className="text-capitalize">{device.deviceinfo.claimwarranty ? 365 - (Math.floor((new Date() - new Date(device.deviceinfo.claimwarrantyDate)) / (1000 * 60 * 60 * 24))) : "-"}</td>
                                            <td>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Link to={`/editDevice/${device._id}`}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={`/deleteDevice/${device._id}`}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={`/viewDevice/${device._id}`}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No devices Warranty will be active</p>
                        )}
                    </>
                </div>
            </section>
        </div>
    );
};

export default DeviceWarranty;
