// AuthContext.js
import React, { createContext, useReducer, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, isLoggedIn: true, user: action.payload.user, token: action.payload.token };
        case "LOGOUT":
            return { ...state, isLoggedIn: false, user: null, token: null };
        default:
            return state;
    }
};

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        isLoggedIn: false,
        user: null,
        token: null,
    });

    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        const storedLoggedIn = localStorage.getItem("loggedIn") === "true";

        if (storedToken) {
            // Validate the token on the server (optional) and update the state
            dispatch({ type: "LOGIN", payload: { user: null, token: storedToken } });

            // Redirect based on the loggedIn value
            if (storedLoggedIn) {
                // Redirect to the dashboard if loggedIn is true
                // navigate('/dashboard');
            } else {
                // Redirect to the login page if loggedIn is false
                navigate('/login');
            }
        }
    }, [navigate]);

    const login = (user, token) => {
        localStorage.setItem("token", token);
        localStorage.setItem("loggedIn", true);
        dispatch({ type: "LOGIN", payload: { user, token } });
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.setItem("loggedIn", false);
        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider value={{ state, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export { AuthProvider, useAuth };
