import React from 'react';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Nopage from './pages/Nopage';
import Sidebar from './components/sidebar';
import Header from './components/header';
import Recentrequest from './pages/Recentrequest';
import VendorRegister from './pages/VendorRegister';
import TechnicianRegister from './pages/TechnicianRegister';
import WarrantyInprogress from './pages/WarrantyInprogress';
import DeviceWarranty from './pages/DeviceWarranty';

export default function App() {
  const isLoggedIn = window.localStorage.getItem("loggedIn");

  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/login" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/recent-request" element={<Recentrequest />} />
            <Route path="/vendor-register" element={<VendorRegister />} />
            <Route path="/technician-register" element={<TechnicianRegister />} />
            <Route path="/warranty-progress" element={<WarrantyInprogress />} />
            <Route path="/device-warranty" element={<DeviceWarranty />} />
            <Route
              path="/dashboard"
              element={
                isLoggedIn === "true" ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
              }
            />
            <Route path="*" element={<Nopage />} />
          </Routes>
          {isLoggedIn === "true" && <Sidebar /> || <Header />}
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}
