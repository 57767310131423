import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

const TechnicianRegister = () => {

    const [technicians, setTechnicians] = useState([]);

    useEffect(() => {
        getAllTechnicians();
    }, [])

    const getAllTechnicians = async () => {
        try {
            const response = await fetch("https://api.intimeglobal.ae/technicians", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });

            const data = await response.json();

            if (data.status === "ok") {
                setTechnicians(data.technicans);
            }
        } catch (error) {
            console.error("technician not found");
        }
    }


    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, email, phone, password } = formData;

        try {
            const response = await fetch("https://api.intimeglobal.ae/registertechnician", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    email,
                    username,
                    phone,
                    password,
                }),
            });

            const data = await response.json();

            if (data.status === "ok") {
                alert('Registration Successful', 'success');
            } else {
                alert('Registration Error', 'danger');
            }
        } catch (error) {
            console.error('Error during registration:', error.message);
            alert('Registration Error', 'danger');
        }
    };

    return (
        <div className="bg_white_inner">
            <section className="main_content dashboard_part large_header_bg">
                <Header />
                <div className="page_content">
                    <div className="row">
                        <div className="col-xl-6">
                            <h4>All Technicians</h4>
                        </div>
                        <div className="col-xl-6 text-right">
                            <button type="button" class="btn btn-primary w-auto" data-toggle="modal" data-target="#exampleModalCenter">
                                Register New Technician
                            </button>
                        </div>

                        <div className="col-xl-12 mt-4">
                            <table className="table table-striped table-hover" width="100%">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Current Request Id</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {technicians.length > 0 && technicians.map(technician => (
                                        <tr>
                                            <td>{technician.username}</td>
                                            <td>{technician.email}</td>
                                            <td>{technician.phone}</td>
                                            <td>{technician.currentRequestId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header w-100">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <h5 class="modal-title" id="exampleModalLongTitle">Register Technician</h5>
                                        </div>
                                        <div className="col-xl-6 text-right">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <form onSubmit={handleSubmit}>

                                            <label for="username">Username</label>
                                            <input
                                                type="text"
                                                placeholder="Username"
                                                id="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label for="email">Email</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label for="phone">Phone</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Phone"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label for="password">Password</label>
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                id="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />

                                            <button type="submit" class="btn btn-primary mt-4">Register</button>
                                        </form>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </div>
    )
}

export default TechnicianRegister
